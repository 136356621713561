<template>
  <div class="Examine">
    <div style="position: fixed;top: 55px;right: 10px; z-index: 1000;">
        <div id="wrappers">
          <div class="input-data">
              <input type="text" v-model="text" @keyup.enter="Setquer()" required>
              <div class="underline">
                <i class="el-icon-circle-close" v-show="text.length > 0" style="position: absolute;top: -30px;right: 60px;color:#9fa1a7;" @click="text = ''"></i>
                <el-button type="primary" style="position: absolute;top: -35px;right: 1px;" size="mini" icon="el-icon-search" @click="Setquer()"></el-button>
              </div>
              <label>{{ this.$t('All.单号') }}/{{ this.$t('All.订单') }}/{{ this.$t('All.料号') }}/{{ this.$t('All.品名') }}</label>
          </div>
      </div>
    </div>
    <div>
      <template>
        <el-table :data="tableData" style="width: 100%">
          <el-table-column align="center" :label="this.$t('system.序号')" type="index" width="90">
          </el-table-column>
          <el-table-column align="center" prop="Date" :label="this.$t('system.创建日期')" width="160"></el-table-column>
          <el-table-column align="center" prop="Gongdan" min-width="100" :label="$t('All.工单号')"></el-table-column>
          <el-table-column align="center" prop="Type" :label="this.$t('All.类型')" >
            <template slot-scope="scope">
              <el-tag effect="dark" :type="scope.row.Type == 1 ?'danger':scope.row.Type == 2 ?'warning':''">{{ scope.row.Type === 0?$t('All.扫描物料'):scope.row.Type === 1?$t('All.删除物料'):scope.row.Type === 2?$t('All.退料'):'' }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="Username" :label="this.$t('All.操作人')" ></el-table-column>
          <el-table-column align="center" prop="Notes" :label="this.$t('All.备注')" ></el-table-column>
          <el-table-column align="center" prop="Factory" :label="this.$t('All.厂区')" ></el-table-column>
          <el-table-column align="center" prop="Serialcode" :label="this.$t('All.二维码')" width="250px"></el-table-column>
        </el-table>
      </template>
    </div>
    <el-pagination
      style="text-align: center;"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageindex"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="page_size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  data () {
    return {
      tableData: [],
      total: 0,
      page_size: 10,
      pageindex: 1,
      text: '',
      dialogVisible: false,
      prindata: '',
      printer: ''
    }
  },
  created () {
    this.TraceQuery()
  },
  mounted () {

  },
  methods: {
    ...mapMutations(['UserData', 'TabComponentFun']),
    async TraceQuery () {
      const intfs = {
        Pageindex: this.pageindex,
        PageSize: this.page_size,
        UserData: this.$store.state.Login,
        Text: this.text
      }
      const { data: res } = await this.$http.post('/api/Semifinished/GetRecord', intfs)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.total = res.response.TotalCount
      this.tableData = res.response.Record
    },
    Setquer () {
      this.pageindex = 1
      this.TraceQuery()
    },
    UserAdd () {
      this.TabComponentFun('FQCUser')
    },
    exit () {
      this.$router.push('/Login')
    },
    AdminAdd () {
      this.TabComponentFun('FQCAdmin')
    },
    async chongxindata (row) {
      this.prindata = row
      this.dialogVisible = true
    },
    handleSizeChange (val) {
      this.page_size = val
      this.TraceQuery()
    },
    handleCurrentChange (val) {
      this.pageindex = val
      this.TraceQuery()
    },
    handleClose (done) {
      this.$confirm(this.$t('All.确认关闭'), {
        confirmButtonText: this.$t('All.确认'),
        cancelButtonText: this.$t('All.取消'),
        type: 'warning'
      })
        .then(_ => {
          done()
        })
        .catch(_ => {})
    }
  }
}
</script>
<style lang="less" scoped>
  /** talbe表头置顶**/
  /deep/ .el-table {
    overflow: visible;
 }

 /deep/ .el-table__header-wrapper {
    position: sticky;
    top: 0px;
    z-index: 10;
 }
</style>
